import React from "react";

import PageWrapper from "../components/PageWrapper";
import Content from "../sections/career/Content";
import Feature from "../sections/career/Feature";
import Roles from "../sections/career/Roles";
import CTA from "../sections/landing1/CTARow";
import SEO from "../components/seo";

const Career = ({data}) => {
  return (
    <>
      <PageWrapper>
        <SEO title={data.prismicServices.data.meta_title.text} description={data.prismicServices.data.meta_description.text}/>
        <Content data={data.prismicServices.data}/>
        <CTA data={data.prismicCta.data}/>
      </PageWrapper>
    </>
  );
};


export default Career;

export const query = graphql`
query ServicesPage {
  prismicCta(internal: {}) {
    id
    data {
      copy {
        text
      }
      cta_button {
        text
      }
      cta_image {
        url
      }
    }
  }
  prismicServices {
    data {
      services_copy {
        title {
          text
        }
        service_block_text {
          text
          html
        }
      }
      meta_title {
        text
      }
      meta_description {
        text
      }
      title {
        text
      }
    }
  }
}
`